@import '~antd/dist/antd.css';

// .ant-timeline {color: rgba(0, 0, 0, 0.85);}
// .ant-timeline-item-tail {border-left: 2px solid lightgrey;}
// .ant-timeline-item-pending .ant-timeline-item-head {background-color: transparent;}
// .ant-timeline-item-head {background-color: #fff;border: 2px solid transparent;border-radius: 100px;}
// .ant-timeline-item-head-blue {color: @primary-color;border-color: @primary-color;}
// .ant-timeline-item-head-red {color: #ff4d4f;border-color: #ff4d4f;}
// .ant-timeline-item-head-green {color: #52c41a;border-color: #52c41a;}
// .ant-timeline-item-head-gray {color: rgba(0, 0, 0, 0.25);border-color: rgba(0, 0, 0, 0.25);}
// .ant-timeline-item-head-custom {border: 0;border-radius: 0;}
// .ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {border-left: 2px dotted #f0f0f0;}
// .ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {border-left: 2px dotted #f0f0f0;}
// .ant-timeline-rtl .ant-timeline-item-tail {border-right: 2px solid red; border-left: none;}
// .ant-timeline-rtl.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {border-right: 2px dotted #f0f0f0;border-left: none;}
// .ant-timeline-rtl.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {border-right: 2px dotted #f0f0f0;border-left: none;}

.seller-sider {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.ant-layout-sider-children {
  min-width: 200px;
}

.ant-layout-content {
  margin: 30px 300px 0px 310px;
}

.ant-table-wrapper {
  position: fixed;
  width: 70vw;
}

.ant-table-row {
  &.hovered {
    background-color: antiquewhite;
  }
}

/* .ant-col {
  margin-top: 16px;
} */

.ant-card {
  &.inquiry {
    .ant-card-head {
      color: white;
      background-color: rgb(255, 85, 0);
    }
  }
  &.finalized {
    .ant-card-head {
      color: white;
      background-color: rgb(250, 173, 20);
    }
  }
  &.active {
    .ant-card-head {
      color: white;
      background-color: rgb(124, 179, 5);
    }
  }
  &.delivered {
    .ant-card-head {
      background-color: rgb(89, 126, 247);
      color: white;
      // background-color: orange;
    }
  }
  &.cancelled {
    .ant-card-head {
      background-color: rgb(191, 191, 191);
      // background-color: orange;
    }
  }
}
// .ant-card-head {
//   background-color: #fafafa;
// }

.ant-cart ulmargin-f {
  list-style-type: none;
}

.ant-card-meta {
  padding: 10px 0px;
}

// .ant-avatar {
//   margin: 3px;
// }

/* .containerCard .ant-card-body {
  padding: 0px;
} */

.bigIcons {
  font-size: large;
  // color: lightgray;
  // margin: 0;
}

.circleBackground {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #bfbfbf; */
  border-radius: 100%;
  width: 16px;
  height: 16px;
  /* margin: 0px 5px; */
  font-weight: bold;
  font-size: smaller;
  color: white;
  margin: 3px;
}

.headerLogo {
  align-self: center;
  margin-right: 50px;
}

.tagContentHolder {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}

.productTagName {
  display: inline;
}

.ant-descriptions-item-label {
  font-weight: bold;
}

.ant-descriptions-item {
  padding-bottom: 0px;
}

.ant-descriptions-row td {
  padding-bottom: 0px;
}
.ant-descriptions-row th {
  padding-bottom: 0px;
}

.outer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.ant-collapse-item-active .ant-collapse-content{
  background: #fafafa;
}